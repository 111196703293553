import React from "react"
import * as styles from "./blog.module.scss"
const BlogHero = ({ title, published }) => {
  return (
    <div className={styles.hero}>
      <div className="container">
        <h1>{title}</h1>
        <p>{published}</p>
      </div>
    </div>
  )
}

export default BlogHero
